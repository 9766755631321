import React from 'react'
// import axios from 'axios'
// import { apiUrl } from '../../config'
import callApi from '../../utils/ApiCall';
import OrganisationsList from './OrganisationsList'
import { Link } from 'react-router-dom';
import { prepareOrganisations } from '../Helpers'

class ClosedOrganisations extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoadingOrganisations: true,
      organisations: []
    }
  }

  async componentDidMount() {
    this.props.setActiveNav('Organisations')
    await this.fetchClosedOrganisations()
  }

  async fetchClosedOrganisations() {
    const that = this
    // await axios.get(`${apiUrl}/organisations/closed`)
    await callApi('/organisations/closed')
      .then(function (response) {
        // console.log(response);
        // if (!response.data.organisations) {
        if (!response.data.organisations) {
          that.setState({ isLoadingOrganisations: false })
          return
        }

        const organisations = prepareOrganisations(response.data.organisations)

        that.setState({ organisations: organisations }, () => {
          that.setState({ isLoadingOrganisations: false })
        })
      })
      .catch(async function (error) {
        const response = await error.json()
        console.error(response);
      });
  }

  render () {
    return (
      <>
        {/* <h1>
          Organisations
        </h1> */}
        <div className='d-flex align-items-center'>
          <ul className="nav nav-pills">
            <li role="presentation" className="nav-item">
              <Link to="/organisations" className="nav-link">Open</Link>
            </li>
            <li role="presentation" className="nav-item ">
              <Link to="/organisations/closed" className='nav-link active'>Closed</Link>
            </li>
          </ul>

          <Link to='/organisations/new' className='ms-auto btn btn-primary'>
            Add new
          </Link>
        </div>

        <br />

        <OrganisationsList
          isLoadingOrganisations={this.state.isLoadingOrganisations}
          organisations={this.state.organisations}
          type={'closed'}
          showBtn={false}
        />
      </>
    )
  }
}

export default ClosedOrganisations
