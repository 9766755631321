import { useNavigate } from "react-router-dom"

export default function PermissionError({ errorCode, recheckPermissions, handleLogout }) {
  const containerStyles = {
    height: '100vh',
    width: '100vw'
  }

  const navigate = useNavigate()

  const redirectToIndex = () => {
    recheckPermissions()
    navigate('/')
  };

  return (
    <div className="d-flex align-items-center" style={containerStyles}>
      <div className="mx-auto p-4 alert alert-danger bg-white">
        <h1 className="">
          Error: {errorCode}
        </h1>
        <p>You do not have the required permission to view this page.</p>
        <button onClick={() => redirectToIndex()} className='btn btn-primary'>
          Back to index
        </button>

        <button onClick={() => handleLogout()} className="ms-2 btn btn-secondary">
          Sign out
        </button>
      </div>
    </div>
  )
}