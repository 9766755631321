import { useState, useEffect, useCallback } from 'react';
import OrganisationForm from './OrganisationForm';
import { submitSuccess, submitError, successToast, errorToast } from '../Helpers'
import { useNavigate } from "react-router-dom";
import callApi from '../../utils/ApiCall';


export default function NewOrganisation({ setActiveNav }) {
  const [organisation] = useState(null);
  const [formErrorFields, setFormErrorFields] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setActiveNav('Organisations')
  }, [setActiveNav])

  const handleSubmit = useCallback((organisationData) => {
    callApi('/organisations', 'POST', organisationData)
      .then(function (response) {
        if (submitSuccess(response)) {
          successToast('Organisation created!')
          navigate("/organisations")
        }
      })
      .catch(async error => {
        if (submitError(error)) {
          const response = await error.json()
          
          errorToast(response.message)
          setFormErrorFields(response.fields)
        }
      });
  }, [navigate])

  return (
    <div className='row'>
      <h1>New organisation</h1>
      
      <OrganisationForm
        formData={organisation}
        type='New'
        formErrorFields={formErrorFields}
        submitLabel='Save'
        handleSubmit={handleSubmit}
      />
    </div>
  )
}
