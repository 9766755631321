import PropTypes from 'prop-types';
import _ from 'lodash'
import { useCallback } from 'react';

export default function TextInput({ handleChange, title, name, value, type, readonly, focus, error}) {
  TextInput.propTypes = {
    handleChange: PropTypes.func,
    title: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    type: PropTypes.string,
    readonly: PropTypes.bool,
    focus: PropTypes.bool,
    error: PropTypes.object
  }
  
  TextInput.defaultProps = {
    handleChange: (event) => console.info(`New value : ${event.target.value}`),
    title: '',
    name: '',
    value: '',
    type: 'string',
    readonly: false,
    focus: false,
    error: {}
  }

  const handleValue = useCallback((value) => {
    if (type === 'number') return value
    if (type === 'text') return value ? value : ''

    return value
  }, [type])

  let inputClassNames = 'form-control'
  if (readonly) {
    inputClassNames += ' text-bg-light'
  } else if (!_.isEmpty(error)) {
    inputClassNames += ' border-danger'
  }
  const labelClassNames = _.isEmpty(error) ? '' : 'text-danger'

  return (
    <>
      {type === 'hidden' ? (
        <input type={type} value={value} id={name} name={name} onChange={(event) => handleChange(event.target.value)} className={inputClassNames} readOnly={readonly} autoFocus={focus} />
      ) : (
        <div className='my-2'>
          <label htmlFor={name} className={labelClassNames}>{title}</label>
          <br/>
          <div className="from-group">
            <input type={type} value={handleValue(value)} id={name} name={name} onChange={(event) => handleChange(event.target.value)} className={inputClassNames} readOnly={readonly} autoFocus={focus} />
            {!_.isEmpty(error) && (
              <small className='text-danger'>
                {error.message}
              </small>
            )}
          </div>
        </div>
      )}
    </>
  )
}
