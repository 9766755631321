import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import TextInput from '../Form/TextInput'
import SelectInput from '../Form/SelectInput'
import languages from '../languages'
import countries from '../countries'
import { createCountrySelectOptionsList, createLanguageSelectOptionsList } from '../Helpers'
import axios from 'axios'
import Loader from '../Views/Loader'
import { apiUrl } from '../../config'

class OrganisationForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      formData: {
        name: '',
        community: 'Private',
        active: false,
        contact_person: '',
        country_code: '',
        country_name: '',
        language_code: '',
        language_name: '',
        street1: '',
        street2: '',
        postalcode: '',
        city: '',
        license_type: 'B',
        left_envelop: 0,
        start_date: moment().local().format('YYYY-MM-DD'),
        end_date: moment().add(1, 'years').local().format('YYYY-MM-DD'),
        account: {
          uuid: '',
          firstname: '',
          lastname: '',
          email: ''
        }
      },
      loadingLicenseTypes: true,
      formErrors: [],
      licenseTypes: [],
      submitLabel: '',
      loadingSubmit: false
    }
  }

  componentDidMount () {
    if (this.props.formData) {
      this.updateFormData(this.props.formData)
    }

    this.getLicenseTypes()
  }

  async getLicenseTypes() {
    const that = this
    await axios.get(`${apiUrl}/api/licenses/types`)
      .then(function (response) {
        const licenseTypes = response.data.license_types
        that.setState({ licenseTypes: licenseTypes }, () => {
          if (that.props.type === 'New') {
            const defaultLicenseType = licenseTypes.find(type => type.value === 'B')
            const updatedFormData = {
              ...that.state.formData,
              left_envelop: defaultLicenseType.credits
            }
            that.setState({ loadingLicenseTypes: false, formData: updatedFormData })
          } else {
            that.setState({ loadingLicenseTypes: false })
          }
        })
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  updateFormData(data) {
    this.setState({ formData: data })
  }

  handleSubmit(e) {
    e.preventDefault()

    const organisationData = {
      ...this.state.formData
    }

    this.setState({loadingSubmit: true}, () => {
      this.props.handleSubmit(organisationData)
      this.setState({loadingSubmit: false})
    })
  }

  handleChange(fieldName, fieldValue) {
    let updatedFormData = this.state.formData
    updatedFormData[fieldName] = fieldValue
    this.updateFormData(updatedFormData)
  }

  handleAccountChange(fieldName, fieldValue) {
    let updatedFormData = this.state.formData
    if (!updatedFormData.account) {
      updatedFormData.account = {
        firstname: '',
        lastname: '',
        email: ''
      }
    }

    let { firstname, lastname, email } = updatedFormData.account

    switch (fieldName) {
      case 'firstname':
        firstname = fieldValue
        break
      case 'lastname':
        lastname = fieldValue
        break
      case 'email':
        email = fieldValue
        break
      default:
        break
    }
    updatedFormData.account = {
      firstname, lastname, email
    }
    this.updateFormData(updatedFormData)
  }

  handleLanguageChange(fieldValue) {
    const lang = languages.find((lang) => lang.code === fieldValue )
    const newFormData = {
      ...this.state.formData,
      language_code: fieldValue,
      language_name: lang ? lang.name : ''
    }

    this.setState({ formData: newFormData })
  }

  languageCodes() {
    return createLanguageSelectOptionsList(languages, 'Select a language code')
  }

  languageValue(languageCode) {
    if (!languageCode) return ''
    const language = languages.find(l => l.code === languageCode.toLowerCase())

    return `${language.name} - ${language.code.toUpperCase()}`
  }

  handleCountryChange(fieldValue) {
    const country = countries.find((country) => country.code === fieldValue )
    const newFormData = {
      ...this.state.formData,
      country_code: fieldValue,
      country_name: country ? country.name : ''
    }

    this.setState({ formData: newFormData })
  }

  countriesCodes() {
    return createCountrySelectOptionsList(countries, 'Select a country code')
  }

  handleLicenseChange(fieldValue) {
    const license_type = this.state.licenseTypes.find(type => type.value === fieldValue)

    const newFormData = {
      ...this.state.formData,
      license_type: license_type.value,
      left_envelop: this.props.type === 'New' ? license_type.credits.toString() : this.state.formData.left_envelop
    }
    this.setState({ formData: newFormData })
  }

  errorMessage(errorFields, value) {
    return errorFields.find(error => error.field === value)
  }

  errorMessageAccount(errorFields, value) {
    const accountFields = errorFields.find(error => error.field === 'account')
    if (!accountFields) return
    if (!accountFields.messages) return

    return accountFields.messages.fields.find(error => error.field === value)
  }

  accountValue(accountData, field) {
    if (accountData) {
      return accountData[field] ? accountData[field] : ''
    }

    return ''
  }

  render () {
    const { submitLabel, formErrorFields, type } = this.props
    const { loadingLicenseTypes, formData, licenseTypes, loadingSubmit } = this.state
    const accountData = formData.account

    const communityFormValue = type === 'New' ? 'Private' : formData.community
    const communityOptions = [
      { key: 'Private', value: 'Private', name: 'Private' },
      { key: 'Shared', value: 'Shared', name: 'Shared' }
    ]

    return (
      <>
        {loadingLicenseTypes ? (
          <Loader />
        ) : (
          <div className=''>
            <form className="" onSubmit={(e) => this.handleSubmit(e)}>
              <TextInput
                title={'Name'}
                name={'name'}
                value={formData.name}
                type='text'
                focus={true}
                error={this.errorMessage(formErrorFields, 'name')}
                handleChange={(newValue) => this.handleChange('name', newValue)}
              />

              <TextInput
                title={'Contact person'}
                name={'contact_person'}
                value={formData.contact_person}
                type='text'
                error={this.errorMessage(formErrorFields, 'contact_person')}
                handleChange={(newValue) => this.handleChange('contact_person', newValue)}
              />

              <SelectInput
                title={'Country code'}
                name={'country_code'}
                value={formData.country_code}
                options={this.countriesCodes()}
                error={this.errorMessage(formErrorFields, 'country_code')}
                handleChange={(newValue) => this.handleCountryChange(newValue)}
              />

              <TextInput
                title={'Country name'}
                name={'country_name'}
                value={formData.country_name}
                type='text'
                readonly={true}
              />

              <SelectInput
                title={'Language code'}
                name={'language_code'}
                value={this.languageValue(formData.language_code)}
                options={this.languageCodes()}
                error={this.errorMessage(formErrorFields, 'language_code')}
                handleChange={(newValue) => this.handleLanguageChange(newValue)}
              />

              <TextInput
                title={'Language name'}
                name={'language_name'}
                value={formData.language_name}
                type='text'
                readonly={true}
              />

              <TextInput
                title={'Street1'}
                name={'street1'}
                value={formData.street1}
                type='text'
                error={this.errorMessage(formErrorFields, 'street1')}
                handleChange={(newValue) => this.handleChange('street1', newValue)}
              />

              <TextInput
                title={'Street2'}
                name={'street2'}
                value={formData.street2}
                type='text'
                handleChange={(newValue) => this.handleChange('street2', newValue)}
              />

              <TextInput
                title={'Postalcode'}
                name={'postalcode'}
                value={formData.postalcode}
                type='text'
                error={this.errorMessage(formErrorFields, 'postalcode')}
                handleChange={(newValue) => this.handleChange('postalcode', newValue)}
              />

              <TextInput
                title={'City'}
                name={'city'}
                value={formData.city}
                type='text'
                error={this.errorMessage(formErrorFields, 'city')}
                handleChange={(newValue) => this.handleChange('city', newValue)}
              />

              <br />
              <h4>License details</h4>

              <SelectInput
                title={'Community'}
                name={'community'}
                value={communityFormValue}
                options={communityOptions}
                disabled={type === 'New' ? true : false}
                // disabled={formData.community === 'False' ? true : false}
                handleChange={(newValue) => this.handleChange('community', newValue)}
              />

              <SelectInput
                title={'License type'}
                name={'license_type'}
                value={formData.license_type}
                options={licenseTypes}
                handleChange={(newValue) => this.handleLicenseChange(newValue)}
              />

              <TextInput
                title={'Left envelop'}
                name={'left_envelop'}
                value={formData.left_envelop}
                type='number'
                handleChange={(newValue) => this.handleChange('left_envelop', newValue)}
              />

              <TextInput
                title={'Start date'}
                name={'start_date'}
                value={formData.start_date}
                type='date'
                error={this.errorMessage(formErrorFields, 'start_date')}
                handleChange={(newValue) => this.handleChange('start_date', newValue)}
              />

              <TextInput
                title={'End date'}
                name={'end_date'}
                value={formData.end_date}
                type='date'
                error={this.errorMessage(formErrorFields, 'end_date')}
                handleChange={(newValue) => this.handleChange('end_date', newValue)}
              />

              <br />
              <h4>Admin account details</h4>

              {!accountData && type === 'Edit' && (
                <div className='alert alert-danger'>
                  No active admin account found.
                </div>
              )}

              <TextInput
                title={'uuid'}
                name={'account_uuid'}
                value={this.accountValue(accountData, 'uuid')}
                type='hidden'
              />

              <TextInput
                title={'First name'}
                name={'account_firstname'}
                value={this.accountValue(accountData, 'firstname')}
                type='text'
                error={this.errorMessageAccount(formErrorFields, 'firstname')}
                handleChange={(newValue) => this.handleAccountChange('firstname', newValue)}
              />

              <TextInput
                title={'Last name'}
                name={'account_lastname'}
                value={this.accountValue(accountData, 'lastname')}
                type='text'
                error={this.errorMessageAccount(formErrorFields, 'lastname')}
                handleChange={(newValue) => this.handleAccountChange('lastname', newValue)}
              />

              <TextInput
                title={'Email'}
                name={'account_email'}
                value={this.accountValue(accountData, 'email')}
                type='email'
                error={this.errorMessageAccount(formErrorFields, 'email')}
                handleChange={(newValue) => this.handleAccountChange('email', newValue)}
              />

              {/* {errorMessages.length === 0 ? ( '' ) : (
                <>
                  <br />
                  <div className='alert alert-danger p-2' role='alert'>
                    {errorMessages.map(
                      (message) => <li>{message}</li>
                    )}
                  </div>
                </>
              )} */}

              <br />
              <div className='form-group'>
                {loadingSubmit ? (
                  <button className='btn btn-primary' disabled>
                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span className="visually-hidden" role="status">Loading...</span>
                  </button>
                ) : (
                  <input type="submit" className='btn btn-primary' value={submitLabel} />
                )}
                <Link to='/organisations' className="btn btn-link">Cancel</Link>
              </div>
            </form>
          </div>
        )}
      </>
    )
  }
}

export default OrganisationForm