import { useEffect, useState, useCallback } from "react"
import Loader from "../Views/Loader";
import Licenses from "./Licenses";
import Credits from "./Credits";
import callApi from "../../utils/ApiCall";
import { useParams } from "react-router";
import moment from 'moment'

export default function Organisation({ setActiveNav }) {
  const [ loading, setLoading ] = useState(true)
  const [ organisation, setOrganisation ] = useState(null);
  const { uuid } = useParams();
  const [ activeOpenNavPage, setActiveOpenNavPage ] = useState('licenses')
  const [ activeClosedNavPage, setActiveClosedNavPage ] = useState('licenses')

  const loadOrganisationDetails = useCallback(() => {
    callApi(`/organisations/${uuid}/details`)
    .then(function (response) {
      // console.log(response.data);
      const loadedFormData = response.data
      // loadedFormData.start_date = moment(loadedFormData.start_date).local().format('YYYY-MM-DD')
      // loadedFormData.end_date = moment(loadedFormData.end_date).local().format('YYYY-MM-DD')
      setOrganisation(loadedFormData)
      setLoading(false)
    })
    .catch(async function (error) {
      const errorResponse = error.json()
      console.log(errorResponse);
    });
  }, [uuid])

  useEffect(() => {
    setActiveNav('Organisations')
    loadOrganisationDetails()
    
  }, [setActiveNav, loadOrganisationDetails])  

  const openOrdersNavClasses = useCallback((navName) => {
    let classes = 'nav-link'
    if (navName === activeOpenNavPage) {
      classes += ' active'
    }
    return classes
  }, [activeOpenNavPage])

  const closedOrdersNavClasses = useCallback((navName) => {
    let classes = 'nav-link'
    if (navName === activeClosedNavPage) {
      classes += ' active'
    }
    return classes
  }, [activeClosedNavPage])
  
  const removeOrder = useCallback((order) => {
    const updatedUnpayedCreditOrders = [ ...organisation.unpayed_credit_orders.filter(unpayedOrder => unpayedOrder !== order) ]
    const updatedOrganisation = {
      ...organisation,
      unpayed_credit_orders: updatedUnpayedCreditOrders
    }
    setOrganisation(updatedOrganisation)
  }, [organisation])

  const removeLicense = useCallback((license) => {
    const updatedUnpayedLicenseOrders = [ ...organisation.unpayed_license_orders.filter(unpayedLicense => unpayedLicense !== license) ]
    const updatedOrganisation = {
      ...organisation,
      unpayed_license_orders: updatedUnpayedLicenseOrders
    }
    setOrganisation(updatedOrganisation)
  }, [organisation])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h1 className="">
            Organisation: {organisation.name}
          </h1>
          { organisation.active ? (
            <span className='badge bg-success'>Active</span>
          ) : (
            <span className='badge bg-danger'>Not active</span>
          )}

          <div className="mt-2 mb-5 row">
            <div className="">
              <div className="card">
                <div className="card-header">
                  <b>Organisation details</b>
                </div>
                <div className="card-body">
                  <div className="d-flex">
                    <b className="d-block w-25">Credits: </b>
                    <div>
                      { organisation.left_envelop }
                    </div>
                  </div>
                  <div className="d-flex">
                    <b className="d-block w-25">Customer Id:</b>
                    <div>
                      { organisation.customer_id }
                    </div>
                  </div>
                  <div className="d-flex">
                    <b className="d-block w-25">Address:</b>
                    <div>
                      {organisation.street1}, {organisation.postalcode} {organisation.city}, {organisation.country}
                    </div>
                  </div>
                  <div className="d-flex">
                    <b className="d-block w-25">License type: </b>
                    <div>
                      { organisation.license_type }
                    </div>
                  </div>
                  <div className="d-flex">
                    <b className="d-block w-25">Allowed accounts: </b>
                    <div>
                      { organisation.allowed_accounts }
                    </div>
                  </div>
                  <div className="d-flex">
                    <b className="d-block w-25">Community: </b>
                    <div>
                      { organisation.community }
                    </div>
                  </div>
                  <div className="d-flex">
                    <b className="d-block w-25">End date:</b>
                    <div>
                      { moment(organisation.end_date).local().format('DD/MM/YYYY HH:mm:ss') }
                    </div>
                  </div>
                  {organisation.active && (
                    <div className="d-flex">
                      <b className="d-block w-25">Activated at:</b>
                      <div>
                        { moment(organisation.activated_at).local().format('DD/MM/YYYY HH:mm:ss') }
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mb-2'>
            <h2>Open</h2>
            <ul className="ms-auto nav nav-pills">
              <li role="presentation" className="nav-item">
                <button onClick={() => setActiveOpenNavPage('licenses')} className={openOrdersNavClasses('licenses')}>
                  License {organisation.unpayed_license_orders.length}
                </button>
              </li>
              <li role="presentation" className="nav-item ">
                <button onClick={() => setActiveOpenNavPage('credits')} className={openOrdersNavClasses('credits')}>
                  Credits {organisation.unpayed_credit_orders.length}
                </button>
              </li>
            </ul>
          </div>

          {activeOpenNavPage === 'licenses' ? (
            <Licenses
              orders={organisation.unpayed_license_orders}
              emptyText={'No open license orders found'}
              payed={false}
              setActiveSubNavPage={setActiveOpenNavPage}
              removeLicense={removeLicense}
              loadOrganisationDetails={loadOrganisationDetails}
            />
          ) : (
            <Credits
              orders={organisation.unpayed_credit_orders}
              emptyText={'No open credit orders found'}
              payed={false}
              setActiveSubNavPage={setActiveOpenNavPage}
              removeOrder={removeOrder}
              loadOrganisationDetails={loadOrganisationDetails}
            />
          )}

          <div className="mt-5">
            <div className='d-flex align-items-center mb-2'>
              <h2>Closed</h2>
              <ul className="ms-auto nav nav-pills">
                <li role="presentation" className="nav-item">
                  <button onClick={() => setActiveClosedNavPage('licenses')} className={closedOrdersNavClasses('licenses')}>
                    License {organisation.payed_license_orders.length}
                  </button>
                </li>
                <li role="presentation" className="nav-item ">
                  <button onClick={() => setActiveClosedNavPage('credits')} className={closedOrdersNavClasses('credits')}>
                    Credits {organisation.payed_credit_orders.length}
                  </button>
                </li>
              </ul>
            </div>

            {activeClosedNavPage === 'licenses' ? (
              <Licenses
                orders={organisation.payed_license_orders}
                emptyText={'No closed license orders found'}
                payed={true}
                setActiveSubNavPage={setActiveClosedNavPage}
                removeLicense={removeLicense}
              />
            ) : (
              <Credits
                orders={organisation.payed_credit_orders}
                emptyText={'No closed credit orders found'}
                payed={true}
                setActiveSubNavPage={setActiveClosedNavPage}
                removeOrder={removeOrder}
              />
            )}
          </div>
        </>

      )}
    </>
  )
}