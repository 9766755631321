import ClientSideNavigation from './ClientSideNavigation'
import { MsalProvider } from "@azure/msal-react";
import Authorizer from './Authorizer'

function App({ pca }) {
  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <Authorizer />
      </MsalProvider>
    </ClientSideNavigation>
  )
}

export default App