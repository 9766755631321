import React from 'react'
import callApi from '../../utils/ApiCall';
import { successToast } from '../Helpers';
import { useState, useCallback } from 'react';
import moment from 'moment'
import { Link } from 'react-router-dom';

export default function CreditOrderRow({ order, payed, organisationView = false, removeOrder, loadOrganisationDetails }) {
  const [ loadingSubmit, setLoadingSubmit ] = useState(false)

  const markAsPayed = useCallback(async () => {
    setLoadingSubmit(true)
    await callApi(`/organisations/${order.org_uuid}/credit_orders/${order.uuid}`, 'PUT', {})
      .then(function (response) {
        setLoadingSubmit(false)
        successToast('Credit order marked as payed!')
        if (organisationView) {
          loadOrganisationDetails()
        } else {
          removeOrder(order)
        }
      })
      .catch(async function (error) {
        const errorResponse = error.json()
        console.error(errorResponse);
      });
  },[setLoadingSubmit, organisationView, removeOrder, order, loadOrganisationDetails])
  

  return (
    <tr>
      <td>
        { moment(order.created_at).local().format('DD/MM/YYYY HH:mm:ss') }
      </td>
      {!organisationView && (
        <td>
          <Link to={`/organisations/${order.org_uuid}`} className='btn btn-link'>{order.org_name}</Link>
        </td>
      )}
      <td>
        {order.credits}
      </td>
      <td>
        &euro; {order.price}
      </td>
      <td>
      {payed ? (
        <>
          { moment(order.payed_at).local().format('DD/MM/YYYY HH:mm:ss') }
        </>
      ) : (
        <>
          {loadingSubmit ? (
            <button className='btn btn-primary' disabled>
              <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
              <span className="visually-hidden" role="status">Loading...</span>
            </button>
          ) : (
            <button onClick={() => markAsPayed()} className='btn btn-outline-secondary btn-sm'>
              Activate
            </button>
          )}
        </>
      )}
      </td>
    </tr>
  )
}

