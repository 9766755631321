import React from 'react'
// import axios from 'axios'
// import { apiUrl } from '../../config'
import OrganisationsList from './OrganisationsList'
import { Link } from 'react-router-dom';
import { prepareOrganisations } from '../Helpers'
import callApi from '../../utils/ApiCall';

class Organisations extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoadingOrganisations: true,
      organisations: []
    }
  }

  async componentDidMount() {
    this.props.setActiveNav('Organisations')
    await this.fetchOpenOrganisations()
  }

  async fetchOpenOrganisations() {
    const that = this
    // await axios.get(`${apiUrl}/organisations`)
    await callApi('/organisations')
      .then(function (response) {
        const data = response.data
        // console.log(data);

        if (!data.organisations) {
          that.setState({ isLoadingOrganisations: false })
          return
        }

        const organisations = prepareOrganisations(data.organisations)

        that.setState({ organisations }, () => {
          that.setState({ isLoadingOrganisations: false })
        })
      })
      .catch(async function (error) {
        const response = await error.json()
        console.error(response);
        that.props.handleError(response)
      });
  }

  render () {
    return (
      <>        
        <div className='d-flex align-items-center'>
          <ul className="nav nav-pills">
            <li role="presentation" className="nav-item">
              <Link to="/organisations" className="nav-link active">Open</Link>
            </li>
            <li role="presentation" className="nav-item ">
              <Link to="/organisations/closed" className='nav-link'>Closed</Link>
            </li>
          </ul>

          <Link to='/organisations/new' className='ms-auto btn btn-primary'>
            Add new
          </Link>
        </div>

        <br />

        <OrganisationsList
          isLoadingOrganisations={this.state.isLoadingOrganisations}
          organisations={this.state.organisations}
          type={'open'}
          showBtn={false}
        />
      </>
    )
  }
}

export default Organisations
