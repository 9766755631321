import { useEffect, useCallback } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import OrdersList from "./OrdersList"

export default function Orders({ setActiveNav, payed }) {
  const location = useLocation()
  let { type, page } = useParams()

  if (!type) type = 'licenses'

  useEffect(() => {
    setActiveNav('Orders')
  }, [setActiveNav])

  const basePath =  payed ? `/orders/${type}/payed` : `/orders/${type}`
  const url = payed ? `/orders/payed/${type}${page ? `/${page}` : '/1'}` : `/orders/${type}/${page ? page : 1}`

  const pillClassess = useCallback((payed) => {
    if (payed) {
      return location.pathname.includes('payed') ? 'nav-link active' : 'nav-link'
    } else {
      return !location.pathname.includes('payed') ? 'nav-link active' : 'nav-link'
    }
  }, [location])

  const setTabClasses = useCallback((pathname) => {
    if (pathname === 'licenses') {
      return !location.pathname.includes('credits') ? 'nav-link active' : 'nav-link'
    } else if (pathname === 'credits') {
      return location.pathname.includes('credits') ? 'nav-link active' : 'nav-link'
    }
  }, [location])

  const tabPath = useCallback((type) => {
    return `/orders${payed ? '/payed' : ''}${type === 'credits' ? '/credits' : ''}`
  }, [payed])
  
  return (
    <>
      <div className='d-flex align-items-center'>
        <ul className="nav nav-pills">
          <li role="presentation" className="nav-item">
            <Link to="/orders" className={pillClassess(false)}>Open</Link>
          </li>
          <li role="presentation" className="nav-item ">
            <Link to="/orders/payed" className={pillClassess(true)}>Payed</Link>
          </li>
        </ul>
      </div>

      <br />
      
      <div className="">
        <ul className="nav nav-tabs mb-2">
          <li className="nav-item">
            <Link to={tabPath('licenses')} className={setTabClasses('licenses')}>License orders</Link>
          </li>
          <li className="nav-item">
            <Link to={tabPath('credits')} className={setTabClasses('credits')}>Credit orders</Link>
          </li>
        </ul>

        <OrdersList
          url={url}
          payed={payed}
          type={type}
          page={page}
          basePath={basePath}
        />
      </div>
    </>
  )
}