import { useEffect } from "react"
import EmptyCard from "../Views/EmptyCard"
import CreditOrderRow from "../Orders/CreditOrderRow"

export default function Credits({ orders, emptyText, payed, setActiveSubNavPage, loadOrganisationDetails }) {
  useEffect(() => {
    setActiveSubNavPage('credits')
  }, [setActiveSubNavPage])

  return (
    <>
      <h4>Credit orders</h4>

      {orders.length === 0 ? (
          <EmptyCard
            emptyText={emptyText}
            showBtn={false}
          />
        ) : (
          <table className='table table-bordered'>
            <thead>
              <tr className=''>
                <th>Created at</th>
                <th>Credits</th>
                <th>Price</th>
                <th>
                  {payed && (
                    <>
                      Payed at
                    </>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map(
                (order) =>
                <CreditOrderRow
                  key={order.uuid}
                  index={order.uuid}
                  order={order}
                  payed={payed}
                  organisationView={true}
                  loadOrganisationDetails={loadOrganisationDetails}
                />
              )}
            </tbody>
          </table>
        )}
    </>
  )

}