import React from 'react';
import { Link } from 'react-router-dom';

class Dashboard extends React.Component {
  render () {
    return (
      <>
        <div className="row">
          <div className="col-md-3">
            <div className="card d-flex align-items-center py-4">
              <Link to="/organisations">Manage organisations</Link>
            </div>
          </div>

          <div className="col-md-3">
            <div className="card d-flex align-items-center py-4">
              <Link to="/orders">Manage orders</Link>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Dashboard
