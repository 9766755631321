import { useState, useEffect, useCallback } from 'react'
import Loader from '../Views/Loader'
import EmptyCard from '../Views/EmptyCard'
import LicenseOrderRow from '../Orders/LicenseOrderRow'
import CreditOrderRow from '../Orders/CreditOrderRow'
import callApi from '../../utils/ApiCall'
import { Link } from 'react-router-dom'

export default function OrdersList({ url, payed=false, type, page=1, basePath }) {
  const [isLoadingOrders, setIsLoadingOrders] = useState(true)
  const [routeCount, setRouteCount ] = useState(0)
  const [orders, setOrders] = useState([])

  useEffect(() => {
    callApi(url)
      .then(function (response) {
        const data = response.data

        setOrders(data.orders)
        setRouteCount(data.count)

        setIsLoadingOrders(false)
      })
      .catch(function (error) {
        console.error(error);
      })
  }, [url, isLoadingOrders])

  const removeUnpayedOrder = useCallback((order) => {
    const updatedUnpayedOrders = [ ...orders.filter(unpayedOrder => unpayedOrder !== order) ]
      setOrders(updatedUnpayedOrders)
  }, [orders])

  let routePageLinks = []
  const pagesCount = Math.ceil(routeCount / 10)

  if (pagesCount > 1) {
    for (let i = 1; i <= pagesCount; i++) {
      let classes = 'btn'
      switch (i) {
        case 1:
          classes += ' rounded-end-0 border-end-0'
          break;
        case pagesCount:
          classes += ' rounded-start-0'
          break
        default:
          classes += ' rounded-x-0 border-end-0'
          break;
      }

      if (parseInt(page) === i || (!page && i === 1)) {
        classes += ' btn-secondary'
      } else {
        classes += ' btn-outline-secondary'
      }

      routePageLinks.push(
        <Link key={i} to={basePath + '/' + i} className={classes}>
          {i}
        </Link>
      )
    }
  }

  return (
    <>
      {isLoadingOrders ? (
        <Loader />
      ) : (
        <>
          {orders.length === 0 ? (
            <EmptyCard
              emptyText='No orders found'
            />
          ) : (
            <>
              <table className='table table-bordered'>
                <thead>
                  {type === 'licenses' ? (
                    <tr className=''>
                      <th>Created at</th>
                      <th>Organisation</th>
                      <th>License type</th>
                      <th>Periode type</th>
                      <th>Periode</th>
                      <th>Price</th>
                      <th>
                        {payed && (
                          <>
                            Payed at
                          </>
                        )}
                      </th>
                    </tr>
                  ) : (
                    <tr className=''>
                      <th>Created at</th>
                      <th>Organisation</th>
                      <th>Credits</th>
                      <th>Price</th>
                      <th>
                        {payed && (
                          <>
                            Payed at
                          </>
                        )}
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {orders.map(
                    (order) => (
                      type === 'licenses' ? (
                        <LicenseOrderRow
                          key={order.uuid}
                          index={order.uuid.toString()}
                          order={order}
                          payed={payed}
                          removeOrder={removeUnpayedOrder}
                        />
                      ) : (
                        <CreditOrderRow
                          key={order.uuid}
                          index={order.uuid.toString()}
                          order={order}
                          payed={payed}
                          globalView={true}
                          removeOrder={removeUnpayedOrder}
                        />
                      )
                    )
                  )}
                </tbody>
              </table>

              { routeCount > 10 && (
                <div className='d-flex mb-4'>
                  {routePageLinks}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
