import { useEffect } from "react"
import LicenseOrderRow from "../Orders/LicenseOrderRow"
import EmptyCard from "../Views/EmptyCard"

export default function Licenses({ orders, emptyText, payed, setActiveSubNavPage, loadOrganisationDetails }) {
  useEffect(() => {
    setActiveSubNavPage('licenses')
  }, [setActiveSubNavPage])
  
  return (
    <>
      <h4>License orders</h4>

      {orders.length === 0 ? (
        <EmptyCard
          emptyText={emptyText}
          showBtn={false}
        />
      ) : (
        <table className='table table-bordered'>
          <thead>
            <tr className=''>
              <th>Created at</th>
              <th>License type</th>
              <th>Periode type</th>
              <th>Periode</th>
              <th>Price</th>
              <th>
                {payed && (
                  <>
                    Payed at
                  </>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {orders.map(
              (order) =>
              <LicenseOrderRow
                key={order.uuid}
                index={order.uuid}
                order={order}
                payed={payed}
                organisationView={true}
                loadOrganisationDetails={loadOrganisationDetails}
              />
            )}
          </tbody>
        </table>
      )}
    </>
  )

}