import { useState, useEffect } from "react";
import { b2cPolicies, loginRequest } from "../authConfig";
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";

// MSAL imports
import { EventType, InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";

import Pages from './Pages'
import Loader from "./Views/Loader";
import { ErrorComponent } from "./Views/Error";

export default function Authorizer() {
  const { inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // const [ authorized, setAuthorized] = useState(false)
  // const [ requestError, setRequestError] = useState(null)
  const [ status, setStatus ] = useState(null);
  // const [ navPage, setNavPage ] = useState('')

  useEffect(() => {
    // console.log('Authorizer load: ', isAuthenticated);

    const callbackId = instance.addEventCallback((event) => {
      // console.log('pages msal-event: ', event);
      if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && event.payload.account) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth 
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy 
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        // console.log('login success?: ', event);
        if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
          // retrieve the account from initial sing-in to the app
          // console.log('accounts: ', instance.getAllAccounts());
          const originalSignInAccount = instance.getAllAccounts()
              .find(account =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid
                &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub
                &&
                // account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
                account.idTokenClaims['tfp'] === b2cPolicies.names.signIn
              );

          // console.log('originalSignInAccount: ', originalSignInAccount);
          
          // let signUpSignInFlowRequest = {
          let signInFlowRequest = {
              // authority: b2cPolicies.authorities.signUpSignIn.authority,
              authority: b2cPolicies.authorities.signIn.authority,
              account: originalSignInAccount
          };
  
          // silently login again with the signUpSignIn policy
          const acquireTokenSilent = instance.ssoSilent(signInFlowRequest);
          // const acquireTokenSilent = instance.acquireTokenSilent(signUpSignInFlowRequest)
          // console.log(acquireTokenSilent);
          acquireTokenSilent.then(function (response) {
            // call API
            return
          }).catch( function (error) {
              // console.log('inProgress: ', inProgress);
              // call acquireTokenPopup in case of acquireTokenSilent failure
              // due to interaction required
              if (error instanceof InteractionRequiredAuthError) {
                // console.log(error);
                // instance.acquireTokenPopup(signUpSignInFlowRequest).then(
                instance.acquireTokenPopup(signInFlowRequest).then(
                    function (response) {
                      // call API
                      console.error(response);
                      return
                    }).catch(function (error) {
                        console.log(error);
                });
              }

              instance.clearCache()
              instance.loginRedirect(loginRequest)
          });
        }
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        instance.clearCache()
        instance.loginRedirect(loginRequest)
      }

      // if (event.eventType === EventType.SSO_SILENT_SUCCESS && event.payload.account) {
      //   setStatus("ssoSilent success");
      // }
      // console.log('status', status);
    });
    
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    }
        
  // eslint-disable-next-line  
  }, []);

  const handleLogin = () => {
    // if (loginType === "popup") {
    //     instance.loginPopup(loginRequest);
    // } else if (loginType === "redirect") {
        instance.loginRedirect(loginRequest);
    // }
  }

  if (isAuthenticated) {
    const authRequest = {
      ...loginRequest
    };

    return (
      <MsalAuthenticationTemplate 
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest} 
        errorComponent={ErrorComponent} 
        loadingComponent={Loader}
      >
        <Pages />
      </MsalAuthenticationTemplate>
    )
  } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
    return (
      <div className='text-center mx-auto my-5'>
        <div className='mb-5 d-inline-flex align-items-center'>
          <img src='/static/img/logo_website.png' alt='Logo Memomove' />
          <h4 className='ms-2 mb-0'>
            Memomove
          </h4>
        </div>

        <h5 className="mb-2">Management portal</h5>
        <button onClick={() => handleLogin()} key="loginPopup" className='btn btn-outline-dark d-flex align-items-center mx-auto rounded-0'>
          {/* <img
            src='https://learn.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-apps/ms-symbollockup_mssymbol_19.svg'
            alt='Microsoft Logo'
            className='me-2'
          /> */}
          Sign in
        </button>
      </div>
    )
  } else {
    return <Loader />
  }
}