import React from 'react'
import { Link } from 'react-router-dom';
import { communityValue, licenseTypes } from '../Helpers';
import { truncateString } from '../Helpers';

class OrganisationRow extends React.Component {
  licenseType(type) {
    let license = licenseTypes().find(license => license.key === type)
    return license.value
  }

  render() {
    const { organisation, type } = this.props
    const editUrl = `/organisations/${organisation.uuid}/edit`
    // console.log(organisation);

    return (
      <tr>
        <td>
          {organisation.id}
        </td>
        <td>
          {communityValue(organisation.community)}
        </td>
        {type === 'open' && (
          <td>
            { organisation.active ? (
              <span className='badge bg-success'>Active</span>
            ) : (
              <span className='badge bg-danger'>Not active</span>
            )}
          </td>
        )}
        <td>
          <Link to={organisation.uuid}>
            {truncateString(organisation.name, 32)}
          </Link>
        </td>
        <td>
          {organisation.unpayed_license_orders_count + organisation.unpayed_credit_orders_count}
        </td>
        <td>
          {this.licenseType(organisation.license_type)}
        </td>
        <td>
          {organisation.left_envelop}
        </td>
        <td>
          {organisation.end_date}
        </td>
        <td>
          <Link to={editUrl} className='btn btn-outline-secondary btn-sm'>
            Edit
          </Link>
        </td>
      </tr>
    )
  }
}

export default OrganisationRow
