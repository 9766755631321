import React from 'react'
import EmptyCard from '../Views/EmptyCard'
import Loader from '../Views/Loader';
import OrganisationRow from './OrganisationRow'

class OrganisationsList extends React.Component {
  render() {
    const { isLoadingOrganisations, organisations, showBtn, type } = this.props;
    return (
      isLoadingOrganisations ? (
        // 'Loading...'
        <Loader />
      ): (
        organisations.length === 0 ? (
          <EmptyCard
            emptyText='No organisations found'
            createUrl='/organisations/new'
            buttonText='Create a new organisation'
            showBtn={showBtn}
          />
        ) : (
          <table className='table table-bordered'>
            <thead>
              <tr className=''>
                <th>Id</th>
                <th>Community</th>
                {type === 'open' && (
                  <th>Active</th>
                )}
                <th>Name</th>
                <th>Open orders</th>
                <th>License</th>
                <th>Left envelop</th>
                <th>End date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {organisations.map(
                (organisation) =>
                <OrganisationRow
                  key={organisation.id}
                  index={organisation.id.toString()}
                  organisation={organisation}
                  type={type}
                />
              )}
            </tbody>
          </table>
        )
      )
    )
  }
}

export default OrganisationsList
