// import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from "react-router-dom"
import { submitSuccess, submitError, successToast, errorToast } from '../Helpers'
import callApi from '../../utils/ApiCall'
import { useParams } from 'react-router-dom'
import OrganisationForm from './OrganisationForm'
import Loader from '../Views/Loader'
import moment from 'moment'

export default function EditOrganisation({ setActiveNav }) {
  const [organisation, setOrganisation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formErrorFields, setFormErrorFields] = useState([]);
  const navigate = useNavigate();
  const { uuid } = useParams();
  
  useEffect(() => {
    setActiveNav('Organisations')
    callApi(`/organisations/${uuid}`)
      .then(function (response) {
        const loadedFormData = response.data
        loadedFormData.start_date = moment(loadedFormData.start_date).local().format('YYYY-MM-DD')
        loadedFormData.end_date = moment(loadedFormData.end_date).local().format('YYYY-MM-DD')
        setOrganisation({
          ...loadedFormData,
          account: loadedFormData.account ? loadedFormData.account : []
        })
        setLoading(false)
      })
      .catch(async function (error) {
        const errorResponse = error.json()
        console.log(errorResponse);
      });
  }, [setActiveNav, uuid])

  const handleSubmit = useCallback((organisationData) => {
    callApi(`/organisations/${uuid}`, 'PUT', organisationData)
      .then(function (response) {
        if (submitSuccess(response)) {
          navigate("/organisations")
          successToast('Organisation updated!')
        }
      })
      .catch(async function (error) {
        if (submitError(error)) {
          const response = await error.json()
          errorToast(response.message)
          setFormErrorFields(response.fields)
        }
      });
  }, [uuid, navigate])
  
  return (
    <>
      <div className='row'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/organisations">Organisations</a></li>
            <li className="breadcrumb-item active" aria-current="page">Edit organisation</li>
          </ol>
        </nav>
      </div>

      <div className='row'>
        <h1>Edit organisation</h1>
        
        {loading ? (
          <Loader />
        ) : (
          <OrganisationForm
            formData={organisation}
            formErrorFields={formErrorFields}
            type='Edit'
            submitLabel='Save'
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </>
  )
}
