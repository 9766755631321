import {
  Routes,
  Route,
  Link,
} from 'react-router-dom'

import Dashboard from './Dashboard'
import Organisations from './Organisations/Organisations'
import ClosedOrganisations from './Organisations/ClosedOrganisations'
import NewOrganisation from './Organisations/NewOrganisation'
import EditOrganisation from './Organisations/EditOrganisation'
import Organisation from './Organisations/Organisation'
import Orders from './Orders/Orders'

import { useState, useEffect, useCallback } from "react";
import { useMsal } from "@azure/msal-react";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PermissionError from './Views/PermissionError'
import Loader from './Views/Loader'
import callApi from '../utils/ApiCall'

export default function Pages() {
  const { instance } = useMsal();
  const [ user, setUser ] = useState(null)
  const [ checkingPermissions, setCheckingPermissions] = useState(true)
  const [ requestError, setRequestError] = useState(null)
  const [ navPage, setNavPage ] = useState('Home')

  const checkAuthLevel = useCallback(async() => {
    const account = instance.getActiveAccount();

    if (account) {
      callApi('/api/auth/check')
        .then(function (response) {
          const authData = response.data
          if (authData.account.isManager) {
            setUser(authData.account)
            setRequestError(null)
            setCheckingPermissions(false)
          } else {
            handleError({ code: 403 })
          }
        })
        .catch(async function (error) {
          const errorResponse = await error.json()
          console.error(errorResponse);
          handleError(errorResponse)
        });
    }
  }, [instance])

  useEffect(() => {
    if (checkingPermissions) checkAuthLevel()
  }, [checkingPermissions, checkAuthLevel])

  const recheckPermissions = () => {
    setCheckingPermissions(true)
  }

  const handleLogout = () => {
    const logoutConfirm = window.confirm('Are you sure?')
    if (logoutConfirm) {
      instance.logoutRedirect();
    }
  };

  const handleError = (error) => {
    // console.log('Error: ', error);

    setRequestError(error)
    setCheckingPermissions(false)
  }

  const navLinkClasses = useCallback((page) => {
    return navPage === page ? 'nav-link px-2 text-secondary' : 'nav-link px-2'
  }, [navPage])

  if (checkingPermissions) {
    const loaderContainerStyles = {
      height: '100vh',
      width: '100vw'
    }
    return (
      <div className='d-flex align-items-center' style={loaderContainerStyles}>
        <Loader />
      </div>
    )
  } else {
    if (requestError) {
      return (
        <>
          <PermissionError
            errorCode={requestError.code}
            recheckPermissions={recheckPermissions}
            handleLogout={handleLogout}
          />
        </>
      )
    }

    // const navUrl = `/${navPage.toLowerCase()}`

    return (
      <>
        <header className="p-3 border-bottom">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
              <a href="/" className="d-flex align-items-center text-decoration-none">
                <img src='/static/img/logo_website.png' alt='Logo Memomove' />
              </a>
              <h4 className='ms-4 mb-0'>
                Management portal
              </h4>

              <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 ms-4 justify-content-center mb-md-0">
                <li><Link to="/" className={navLinkClasses('Home')}>Home</Link></li>
                <li><Link to="/organisations" className={navLinkClasses('Organisations')}>Organisations</Link></li>
                <li><Link to="/orders" className={navLinkClasses('Orders')}>Orders</Link></li>
              </ul>
              <div className='ms-auto d-flex align-items-center ms-4'>
                {user.firstname}
                <button onClick={() => handleLogout()} key="loginPopup" className='ms-4 btn btn-sm btn-outline-secondary'>
                  {/* Log out */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="none" strokeWidth="1.5" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M12 12h7m0 0-3 3m3-3-3-3M19 6V5a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="container py-4">
          <Routes>
            <Route
              path="organisations"
              element={
                <Organisations
                  setActiveNav={setNavPage}
                />
              }
            />
            <Route
              path="organisations/:uuid/*"
              element={
                <Organisation
                  setActiveNav={setNavPage}
                />
              }
            />
            <Route
              path="organisations/closed"
              element={
                <ClosedOrganisations
                  setActiveNav={setNavPage}
                />
              }
            />
            <Route
              path="organisations/new"
              element={
                <NewOrganisation
                  setActiveNav={setNavPage}
                />
              }
            />
            <Route
              path="organisations/:uuid/edit"
              element={
                <EditOrganisation 
                  setActiveNav={setNavPage}
                />
              }
            />
            <Route
              path="orders/:type?/:page?"
              element={
                <Orders
                  setActiveNav={setNavPage}
                  payed={false}
                />
              }
            />
            <Route
              path="orders/payed/:type?/:page?"
              element={
                <Orders
                  setActiveNav={setNavPage}
                  payed={true}
                />
              }
            />
            <Route index
              element={
                <Dashboard />
              }
            />
          </Routes>
        </div>
        <ToastContainer />
      </>
    );
  }
}