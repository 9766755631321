import { toast } from 'react-toastify'
import moment from 'moment'
import PermissionError from './Views/PermissionError'

export function prepareOrganisations(organisations) {
  return organisations.map((organisation) => {
    return {
      ...organisation,
      start_date: moment(organisation.start_date).local().format('DD/MM/YYYY'),
      end_date: moment(organisation.end_date).local().format('DD/MM/YYYY')
    }
  })
}

export function submitSuccess(response) {
  return response.status === 200 && response.data.success
}

export function submitError(response) {
  return response.status !== 200 && !response.ok
}

export function successToast(text) {
  return toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function errorToast(text) {
  return toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function communityValue(community) {
  return community === 'Shared' ? 'Shared' : 'Private'
}

export function createSelectOptionsList(input, prompt, formatType) {
  let output = input.map((line) => {
    let nameFormat
    if (formatType === 'country') {
      nameFormat = `${line.code.toUpperCase()} - ${line.name}`
    } else if (formatType === 'language') {
      nameFormat = `${line.name} - ${line.code.toUpperCase()}`
    }
    return { key: line.code, value: line.code, name: nameFormat }
  })
  output.unshift({ key: '', value: '', name: prompt })
  return output
}

export function createLanguageSelectOptionsList(input, prompt) {
  return createSelectOptionsList(input, prompt, 'language')
}

export function createCountrySelectOptionsList(input, prompt) {
  return createSelectOptionsList(input, prompt, 'country')
}

export function licenseTypes() {
  return [
    { key: 'F', value: 'Free' },
    { key: 'B', value: 'Bronze' },
    { key: 'Z', value: 'Silver' },
    { key: 'G', value: 'Gold' }
  ]
}

export function licenseTypesOptionsList() {
  return [
    { key: '', value: '', name: 'Select a license' },
    { key: 'F', value: 'F', name: 'Free' },
    { key: 'B', value: 'B', name: 'Bronze' },
    { key: 'Z', value: 'Z', name: 'Silver' },
    { key: 'G', value: 'G', name: 'Gold' }
  ]
}

export function errorHandler(error) {
  console.log(error);

  switch (error.status) {
    case 403:
      // Render forbidden
      
      break;
    case 500:
      // Render internal server error
      break;
    case 401:
      // Render not found
      break;
    default:
      break;
  }

  return ( <PermissionError /> )
}

export function truncateString(str, num) {
  if (str.length <= num) return str
  return str.slice(0, num) + '...'
}
